<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav
        :pageRow="{ value: pageLengthPer, type: 'trabajosHeigth' }"
        :editColsBottom="{ badgeText: 'Editar cols' }"
        :dataColumns="{
          colsShow: columnasMostrar,
          colsList: listaColumnas,
          endpointEdit: 'venta_trabajo',
          materia: null,
          periodo: null,
        }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :deleteBottom="{
          badgeText: 'Eliminar trabajo',
          tipoEndpoint: 'borrar_venta_trabajo',
          endpoint: '/ventas',
          atributo: 'ventaId',
        }"
        :editRowBottom="{
          badgeText: 'Editar trabajo',
          tipo: 'editTrabajo',
        }"
        :copyRowBottom="{ badgeText: 'Copiar trabajo' }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          show: true,
          nameDoc: 'Trabajos',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:toggle="eventosTrabajos($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container class="contenedor-tabla" v-show="mostrarTable == true">
        <vue-excel-editor
          ref="grid"
          v-if="mt"
          v-show="mostrarTable == true"
          v-model="jsondata"
          :page="pageLengthPer"
          filter-row
          :localized-label="myLabels"
          @cell-click="position"
          remember
          @select="onSelect"
          :cell-style="metodoColorBack"
          :enterToSouth="enterAbajo"
          v-on:toggle-table="activeRigthClick($event)"
          @cell-focus="getLabelColor"
        >
          <vue-excel-column
            field="ID"
            label="ID"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Fecha"
            label="Fecha"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Línea"
            label="Línea"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono"
            label="Teléfono"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono 2"
            label="Teléfono 2"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Correo"
            label="Correo"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Materia"
            label="Materia"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Fase de trabajo"
            label="Fase de trabajo"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Tutor"
            label="Tutor"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="LINK DRIVE"
            label="LINK DRIVE"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Notas al Encargado"
            label="Notas al Encargado"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Archivos necesarios"
            label="Archivos necesarios"
            type="select"
            :change="openCrud"
            :options="['Ver archivos']"
          />
          <vue-excel-column
            field="ANOTACIONES"
            label="ANOTACIONES"
            type="string"
            :change="addAnotacion"
          />
          <vue-excel-column
            field="Estado"
            label="Estado"
            :change="cambioEstado"
            type="select"
            :options="[
              'MAL MIGRADO',
              'NO INGRESA CORREO',
              'NO INGRESA PLATAFORMA',
              'NO LLEGA PROUNAD',
              'VERIFICACION CORREO',
              'CANCELADO POR DEMORA',
              'ENVIADO A CLIENTE',
              'FINALIZADO CORREO',
              'FINALIZADO',
              'NUNCA ENVIADO',
              'CANCELADO',
              'NUEVO',
              'OK',
            ]"
          />
          <vue-excel-column
            field="Link del trabajo"
            label="Link del trabajo"
            type="select"
            :change="openCrud"
            :options="['Ver trabajo']"
          />
          <vue-excel-column
            field="Fecha de envío"
            label="Fecha de envío"
            :validate="valid"
          />
          <vue-excel-column
            field="Valor Acordado"
            label="Valor Acordado"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Valor Recaudado"
            label="Valor Recaudado"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Valor a Empleado"
            label="Valor a Empleado"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="WhatsApp"
            label="WhatsApp"
            :change="openWA"
            type="select"
            :options="['Ir a WhatsApp']"
            key-field
            :invisible="false"
          />
          <vue-excel-column
            field="Pagos"
            label="Pagos"
            :change="openPagos"
            type="select"
            :options="['Ver pagos']"
            key-field
            :invisible="false"
          />
          <vue-excel-column
            label="Editar venta"
            :change="editT"
            type="select"
            :options="['Editar ventas']"
            key-field
            :invisible="false"
          />
        </vue-excel-editor>
      </v-container>
      <template>
        <!-- cargar los archivos -->
        <v-dialog v-if="dialog" v-model="dialog" scrollable max-width="50%">
          <crud-archivos
            v-on:toggle="toggleArchivos($event)"
            :title="'Ver los archivos cargados'"
            :subtitle="'Cargar archivos'"
            :textVer="'Ver link '"
            :listData="listaArchivos"
            :cargar="true"
            :eliminar="true"
            tipo="archivosVentaTrabajo"
            :info="trabajo"
          >
          </crud-archivos>
        </v-dialog>

        <v-dialog v-if="dialogT" v-model="dialogT" scrollable max-width="50%">
          <crud-archivos
            v-on:toggle="toggleArchivos($event)"
            :title="'Ver los archivos cargados'"
            :subtitle="'Archivos cargados por el docente'"
            :textVer="'Ver link '"
            :listData="listaArchivosT"
            :cargar="false"
            :eliminar="false"
            tipo="ver"
            :info="null"
          >
          </crud-archivos>
          <!-- <ver-archivos v-on:toggle="toggleTrabajos($emit)" :title="'Ver trabajo cargado'"
            :info="'* el docente debe cargar esta información'" :listData="listaTrabajo" :ver="'Ver trabajo '"
            tipo="trabajo"
            :error="'Aún no hay archivos cargados por el docente'"></ver-archivos> -->
        </v-dialog>
        <!-- dialog de WA -->
        <v-dialog v-if="dialogW" v-model="dialogW" max-width="30%">
          <whats-app
            v-on:eventosWa="eventosWApp"
            :name="nameCard"
            :optionsW="optionsW"
          ></whats-app>
        </v-dialog>
        <!-- Dialogo de pagos -->
        <v-dialog v-if="dialogPagos" v-model="dialogPagos" max-width="95%">
          <pagos-abonos
            v-on:toggle="togglePagos($event)"
            :cliente="nameCard"
            :listaPagos="listaPagos"
            :valorAcordado="valorAcordado"
            :valorRecaudado="valorRecaudado"
            :valorRestante="valorRestante"
            :tipo="'normal'"
            :mPagos="mPagos"
            :estadoP="estadoP"
            :trabajo="true"
          ></pagos-abonos>
        </v-dialog>
        <!-- Dialogo de popup -->
        <v-dialog v-if="infoP" v-model="infoP" max-width="40%">
          <info-p
            v-on:toggle="toggleMSJ($event)"
            :title="'Ten en cuenta al crear la venta del trabajo'"
            :text="mensaje"
          ></info-p>
        </v-dialog>
        <v-dialog v-if="editVenta" v-model="editVenta" max-width="80%">
          <venta-trabajo-view
            v-if="editVenta == true"
            :title="'Editar la venta de trabajo de '"
            :nameCard="nameCard"
            :info="trabajo"
            :idCli="idCli"
            :frecV="frec"
            :listaLink="listaLink"
            v-on:toggle="eventosVenta($event)"
          >
          </venta-trabajo-view>
        </v-dialog>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import WhatsApp from "./component/WhatsApp.vue";
// import PagosAbonos from "./component/RegistrarPagos.vue";
// import InfoP from "./component/infoP.vue";
// import VentaTrabajoView from "./component/VentaTrabajoView.vue";
import ToolBarNav from "./component/ToolBarNav.vue";
import infoSelected from "./Tools/itemSelected";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],
  components: {
    ToolBarNav,
    WhatsApp: () =>
      import(/* webpackChunkName: "WhatsApp"  */ "./component/WhatsApp.vue"),
    PagosAbonos: () =>
      import(
        /* webpackChunkName: "PagosAbonos"  */ "./component/RegistrarPagos.vue"
      ),
    InfoP: () =>
      import(/* webpackChunkName: "InfoP"  */ "./component/infoP.vue"),
    VentaTrabajoView: () =>
      import(
        /* webpackChunkName: "VentaTrabajoView"  */ "./component/VentaTrabajoView.vue"
      ),
    CrudArchivos: () =>
      import(
        /* webpackChunkName: "CrudArchivos"  */ "./component/CrudArchivos.vue"
      ),
  },
  data() {
    return {
      mt: false,
      textoActionToolBar: [],
      jsondata: [],
      editarColumnas: false,
      pageLengthPer: this.$store.state.trabajosHeigth,
      listaColumnas: [],
      columnasMostrar: [],
      mostrarTable: false,
      dialogPagos: false,
      listaPagos: {},
      menuPE: false,
      datePE: new Date().toISOString().substr(0, 10),
      menu: false,
      date: new Date(),
      dataFA: new Date().toISOString().substr(0, 10),
      medioVenta: [],
      periodos: [],
      materiasByp: [],
      files: [],
      files2: [],
      editVenta: false,
      dialog: false,
      nameCard: null,
      valorAcordado: null,
      materiasList: [],
      dialogW: null,
      valorRestante: null,
      valorRecaudado: null,
      listaCursos: [],
      trabajo: {
        id: null,
        linea: null,
        agenteVenta: null,
        materia: null,
        periodo: null,
        medioVenta: null,
        tutor: null,
        docente: [],
        cuotasTotales: null,
        valorAcordado: null,
        notas: null,
      },
      id: null,
      listaDocentes: [],
      dataWA: [],
      listaLink: [],
      dataLink: [],
      dataLinkT: [],
      listaArchivos: [],
      listaArchivosT: [],
      dataPagos: [],
      dialogT: false,
      searchDoc: null,
      infoP: false,
      search: null,
      rowWA: null,
      idT: null,
      bases64: "",
      mensaje: [],
      onSelected: null,
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: true,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
    };
  },

  mounted() {
    // this.datos();
    this.initialize();
    document.title = "Trabajos Admin";
  },

  async created() {
    await this.cargueColors();
  },

  methods: {
    initialize() {
      this.mt = false;
      this.jsondata = [];
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar_trabajos");
          axios.post(host + "/ventas", fd).then((result) => {
            let data = result.data.lista;
            if (result.data.text == "OK") {
              this.pageLength = result.data.length;
              this.buscando = false;
              for (let i = 0; i < data.length; i++) {
                var vA = Intl.NumberFormat("de-DE").format(
                  data[i]["Valor acordado"]
                );
                var vR = Intl.NumberFormat().format(data[i]["Valor Recaudado"]);
                data[i]["Valor acordado"] = vA;
                data[i]["Valor Recaudado"] = vR;
                data[i]["Correo"] =
                  data[i]["Correo"] == "null" ? "-" : data[i]["Correo"];
                data[i]["Estado"] =
                  data[i]["Estado"] == "null"
                    ? "-"
                    : data[i]["Estado"] == "undefined"
                    ? "-"
                    : data[i]["Estado"];
                data[i]["Fase de trabajo"] =
                  data[i]["Fase de trabajo"] == "null"
                    ? "-"
                    : data[i]["Fase de trabajo"] == "undefined"
                    ? "-"
                    : data[i]["Fase de trabajo"];
                this.dataWA.push({
                  id: data[i]["_id"],
                  data: data[i]["WhatsApp"],
                });
                data[i]["WhatsApp"] = data[i]["WhatsApp"] == "null" ? "-" : "";
                data[i]["Fecha de envío"] =
                  data[i]["Fecha de envío"] == null
                    ? "-"
                    : this.$moment(data[i]["Fecha de envío"]).format(
                        "YYYY/MM/DD - HH:mm:ss"
                      );
                data[i]["Link del trabajo"] =
                  data[i]["Link del trabajo"] == "null" ? "-" : "";
                this.dataPagos.push({
                  id: data[i]["_id"],
                  data: data[i]["Pagos"],
                });
                data[i]["Pagos"] = data[i]["Pagos"] == "null" ? "-" : "";

                this.dataLink.push({
                  id: data[i]["_id"],
                  data: data[i]["archivos"],
                });
                this.dataLinkT.push({
                  id: data[i]["_id"],
                  data: data[i]["archivosTrabajo"],
                });
                data[i]["Archivos necesarios"] =
                  data[i]["Archivos necesarios"] == "null" ? "-" : "";
                data[i]["LINK DRIVE"] =
                  data[i]["LINK DRIVE"] == "undefined"
                    ? ""
                    : data[i]["LINK DRIVE"];
                data[i]["Notas al Encargado"] =
                  data[i]["Notas al Encargado"] == "null"
                    ? "-"
                    : data[i]["Notas al Encargado"];
                //     data[i]['Materias Repo'] = data[i]['Materias Repo'] == 'null' ? '-' : ''
                data[i]["Fecha"] =
                  data[i]["Fecha"] == null
                    ? "-"
                    : this.$moment(data[i]["Fecha"]).format(
                        "YYYY/MM/DD - HH:mm:ss"
                      );
              }
              this.jsondata = data;
              if (this.jsondata.length == 0) {
                this.$alert("No existen ventas");
              } else {
                this.mostrarTable = true;
                this.listaColumnas = result.data.visibilidad;
                for (var k = 0; k < this.listaColumnas.length; k++) {
                  if (this.listaColumnas[k]["valor"] == true) {
                    this.columnasMostrar.push(this.listaColumnas[k]);
                  }
                }
                this.cargueCol(["Pagos", "WhatsApp"]);
              }
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    openPagos(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.dialogPagos = true;
      this.valorAcordado = row.valorAcordado;
      this.valorRestante = row.saldoRestante;
      this.valorRecaudado = row.valorRecaudado;
      this.nameCard = row.nombre;
      const resObject = this.dataPagos.find((item) => item.id === row._id);
      this.listaPagos = resObject.data;
      return false;
    },

    openCrud(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.nameCard = row.nombre;
      if (newVal.trim() == "Ver archivos") {
        const resObject = this.dataLink.find((item) => item.id === row._id);
        this.listaArchivos = resObject.data;
        this.dialog = true;
        this.trabajo = row;
        this.trabajo.materia = row.curso;
        this.trabajo.id = row._id;
        this.nameCard = row.nombre;
        this.trabajo.agenteVenta = row.agenteVentas;
        this.trabajo.fase = row.faseTrabajo;
        this.trabajo.notas = row["Notas al Encargado"];
      } else {
        const resObject = this.dataLinkT.find((item) => item.id === row._id);
        this.listaArchivosT = resObject.data;
        this.dialogT = true;
      }
    },

    editT(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      const resObject = this.dataLink.find((item) => item.id === row._id);
      this.listaLink = resObject.data;
      this.trabajo = row;
      this.trabajo.materia = row.curso;
      this.trabajo.id = row._id;
      this.nameCard = row.nombre;
      this.trabajo.agenteVenta = row.agenteVentas;
      this.trabajo.fase = row.faseTrabajo;
      this.trabajo.notas = row["Notas al Encargado"];
      this.idT = row._id;
      this.editVenta = true;
      return false;
    },

    openWA(oldVal, newVal, row, field) {
      this.$refs.grid.inputBoxBlur();
      console.log(field);
      this.dialogW = true;
      const resObject = this.dataWA.find((item) => item.id === row._id);
      this.optionsW = resObject.data;
      return false;
    },

    closeWA() {
      this.dialogW = false;
      this.rowWA = null;
      this.nameCard = null;
    },

    listFiles(event) {
      this.files = [];
      this.files2 = Array.prototype.slice.call(event.target.files);
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i].name);
        this.lFiles.push(this.$refs.files.files[i]);
      }
      // this.tobase(this.lFiles);
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async tobase(files) {
      const filePathsPromises = [];
      files.forEach((file) => {
        filePathsPromises.push(this.toBase64(file));
      });
      const filePaths = await Promise.all(filePathsPromises);
      this.bases64 = filePaths;
    },

    cambioEstado(newVal, oldVal, row, field) {
      console.log(oldVal, field);
      this.textoActionToolBar.push("Guardando estadoU " + '"' + newVal + '"');
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "editar_estado_trabajo");
          fd.append("estado", newVal.trim());
          fd.append("ventaId", row._id);
          axios.post(host + "/ventas", fd).then((result) => {
            let data = result.data;
            var rem = this.textoActionToolBar.indexOf(
              "Guardando estadoU " + '"' + newVal + '"'
            );
            if (data.text == "OK") {
              this.textoActionToolBar.splice(rem, 1);
              this.initialize();
              this.jsondata = [];
            } else {
              this.textoActionToolBar.splice(rem, 1);
              this.textoActionToolBar.push(
                "Error al guardar " + '"' + newVal + '"'
              );
              this.$alert("No se pudo realizar el ajuste del pago");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "_id");
      if (this.onSelected != null) {
        this.onSelected["mensaje"] =
          "el trabajo del usuario " +
          this.onSelected["field"]["usuario"] +
          " del curso " +
          this.onSelected["field"]["curso"] +
          " con el ID " +
          this.onSelected["field"]["ID"];
      }
      this.rigthClick = false;
    },

    addAnotacion(newVal, oldVal, row, field) {
      console.log(field, oldVal);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("ventaId", row._id);
          fd.append("anotacion", newVal.trim());
          axios.post(host + "/editarObservacionTrabajo", fd).then((result) => {
            let data = result.data;
            if (data.text != "OK") {
              this.$alert("No se pudo agregar la anotación");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    metodoColor(row, col) {
      var verde = ["ENVIADO A CLIENTE", "FINALIZADO CORREO"];
      var rojos = ["CANCELADO", "NUNCA ENVIADO", "MAL MIGRADO"];
      var moradoNuevo = ["NUEVO"];
      var azul = ["FINALIZADO"];
      switch (col.name) {
        case "Estado":
          var rowItemEstado = row["Estado"].trim();
          if (rojos.includes(rowItemEstado)) {
            return {
              "background-color": "#FF0000",
            };
          }
          if (verde.includes(rowItemEstado)) {
            return {
              "background-color": "#34a853",
            };
          }
          if (moradoNuevo.includes(rowItemEstado)) {
            return {
              "background-color": "#c27ba0",
            };
          }
          if (azul.includes(rowItemEstado)) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        default:
          break;
      }
      return {};
    },

    // EVENTOS
    togglePagos(x) {
      if (x == true) {
        this.initialize();
        this.dialogPagos = false;
      }
      if (x == false) {
        this.dialogPagos = false;
      }
    },

    toggleArchivos(event) {
      if (event != "cerrar") {
        this.listaArchivos.splice(event, 1);
      }
      if (event == "cerrar") {
        this.dialog = false;
        this.dialogT = false;
      }
    },

    toggleMSJ() {
      this.mensaje = [];
      this.infoP = false;
    },

    eventosTrabajos(d) {
      if (d == "refresh" || d == "edit") {
        this.initialize();
      }
      if (d == "editTrabajo") {
        this.editT("", "", this.onSelected.field);
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    eventosVenta(v) {
      if (v == "close") {
        this.editVenta = false;
      } else {
        if (v.id != null) {
          this.editVenta = false;
          this.jsondata[this.selectedRow]["Línea"] = v.linea;
          this.jsondata[this.selectedRow]["Materia"] = v.curso;
          this.jsondata[this.selectedRow]["LINK DRIVE"] = v.linkDrive;
          this.jsondata[this.selectedRow]["Contraseña"] = v.password;
          this.jsondata[this.selectedRow]["Notas al Encargado"] =
            v["Notas al Encargado"];
          this.jsondata[this.selectedRow]["Fase de trabajo"] = v.fase;
          this.jsondata[this.selectedRow]["Tutor"] = v.tutor;
          this.jsondata[this.selectedRow]["Valor Acordado"] = v.valorAcordado;
        }
      }
    },

    eventosWApp(d) {
      if (d == false) this.dialogW = false;
    },
  },
};
</script>
